
export default {
  async init({ dispatch, state, commit }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit }, payload) {
    try {
      const response = await this.$axios.get('/api/workers', {
        params: payload.query,
      });
      const { data, links, meta } = response.data;
      if (payload.reset) {
        commit('setWorkers', data);
      } else {
        commit('addWorkers', data);
      }
      commit('setLinks', links);
      commit('setMeta', meta);
      commit('toggleData');
    } catch (err) {
      commit('setMeta', err);
    }
  },

  async clearWorkerPhoneRegistration({ commit }, id) {
    try {
      const response = await this.$axios.put(`/api/me/workers/${id}/profile-update`, {device_id: 'reset'})
      commit('clearWorkerPhoneRegistration')
    } catch (err) {
      console.log(err)
    }
  },

  async fetchDetails({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/api/workers/${payload.slug}`, { 
        params: {
          site_id: payload.site_id
        }
      });
      const { data, links, meta } = response.data;
      commit('setWorker', data);
      commit('toggleData');
    } catch (err) {}
  },
};
